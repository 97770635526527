<template>
  <div class="preview-container">
    <b-overlay
      id="overlay-background"
      :show="loading"
      variant="light"
      opacity="1"
    >
      <div>
        <div class="d-flex">
          <div class="contacts-container">
            <h4 class="title-info">
              {{ $t('partnerContactPage.textContactInformation') }}
            </h4>

            <div>
              <p class="content-title-info">
                {{ $t('common.labelBusinessName') }}
              </p>
              <p
                class="text-content-info"
              >
                {{ businessName }}
              </p>
            </div>

            <div class="line-center" />

            <div class="ref-code">
              <p class="content-title-info">
                {{ $t('partnerContactPage.textRefCode') }}
              </p>

              <a
                :href="refCode"
                target="_blank"
                rel="noopener noreferrer"
                class="text-content-info underline"
              >
                {{ refCode }}
              </a>
            </div>

            <!--            <div class="line-center" />-->

            <!--            <div>-->
            <!--              <p class="content-title-info">-->
            <!--                Phone number-->
            <!--              </p>-->
            <!--              <p-->
            <!--                class="text-content-info"-->
            <!--              >-->
            <!--                {{ phoneNumber }}-->
            <!--              </p>-->
            <!--            </div>-->

            <div class="line-center" />

            <div style="width: 50%">
              <p class="content-title-info">
                {{ $t('stepGuideControl.stepVerifyInfo.labelCountry') }}
              </p>
              <p
                class="text-content-info"
              >
                {{ country }}
              </p>
            </div>

            <div class="line-center" />

            <div style="width: 50%">
              <p class="content-title-info">
                {{ $t('partnerContactPage.textLandingPage') }}
              </p>
              <div>
                <a
                  v-if="landingPage"
                  :href="landingPage"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="underline text-content-info"
                >{{ landingPage }}</a>
                <p
                  v-else
                  class="text-content-info"
                >
                  N/A
                </p>
              </div>
            </div>

            <div class="line-center" />

            <div>
              <div>
                <p class="content-title-info">
                  {{ $t('partnerContactPage.textCommunityGroup') }}
                </p>
                <p
                  class="text-content-info"
                >
                  {{ communityGroup }}
                </p>
              </div>
            </div>
          </div>

          <div
            v-if="formEmbedURL && hasFormWhiteList"
            style="width: 75%;"
          >
            <div style="height: 100%; padding: 16px; border-radius: 14px; background: #F8F8F9;">
              <!-- <embed
                type="text/html"
                :src="formEmbedURL"
                class="embed-url"
              > -->
              <iframe
                :src="formEmbedURL"
                title="test"
                class="embed-url"
                :style="heightInput"
              />
              <div
                ref="urlBox"
                class="url-partner"
              >
                <a
                  :href="formEmbedURL"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="detail-url underline text-blue"
                >{{ formEmbedURL }}</a>
              </div>
            </div>
          </div>
          <div
            v-else
            class="no-ember"
          >
            <div class="__content">
              <feather-icon
                icon="InboxIcon"
                size="48"
              />
              <p>{{ $t('partnerContactPage.textNoEmbed') }}</p>
            </div>
          </div>
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import { BOverlay, VBPopover } from 'bootstrap-vue'
import { createNamespacedHelpers } from 'vuex'
import partnerMixin from '@/mixins/partnerMixin'
import Ripple from 'vue-ripple-directive'

const { mapGetters } = createNamespacedHelpers('auth')

export default {
  components: {
    BOverlay,
  },

  directives: {
    'b-popover': VBPopover,
    Ripple,
  },

  mixins: [partnerMixin],

  data() {
    return {
      heightInput: { height: '100%' },
    }
  },

  computed: {
    ...mapGetters(['user', 'loading']),

    partnershipInfo() {
      return this.user?.data?.belongToPartner
    },

    businessName() {
      return this.partnershipInfo?.partnerId?.fullName
    },

    refCode() {
      return this.partnershipInfo?.refCode
        ? `${process.env.VUE_APP_LOGIN_URL}/register?refcode=${this.partnershipInfo?.refCode}`
        : 'N/A'
    },

    // phoneNumber() {
    //   const contactInfo = this.partnershipInfo?.partnerId?.contactInfo
    //   if (contactInfo?.phone) {
    //     const phoneElm = contactInfo.phone.split('|')
    //     if (phoneElm.length === 3) {
    //       const phone = contactInfo.phone.split('|')
    //       return `+${phone[1]} ${phone[2]}`
    //     }
    //   }
    //   return 'N/A'
    // },

    country() {
      return this.partnershipInfo?.partnerId?.country
    },

    landingPage() {
      return this.partnershipInfo?.partnerId?.partnership?.landingPage
    },

    communityGroup() {
      return this.partnershipInfo?.partnerId?.partnership?.communityGroup || 'N/A'
    },

    // facebook() {
    //   return this.partnershipInfo?.partnerId?.contactInfo?.facebook || 'N/A'
    // },
    //
    // telegram() {
    //   return this.partnershipInfo?.partnerId?.contactInfo?.telegram || 'N/A'
    // },
    //
    // whatsApp() {
    //   return this.partnershipInfo?.partnerId?.contactInfo?.whatsApp || 'N/A'
    // },

    formEmbedURL() {
      return this.user?.data?.belongToPartner?.partnerId?.partnership?.formEmbedURL
    },
  },

  watch: {
    user: {
      handler(data) {
        if (data?.data?.localUserId) {
          this.matchHeight()
        }
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    matchHeight() {
      this.$nextTick(() => {
        this.heightInput.height = `calc(100% - ${(this.$refs.urlBox?.clientHeight + 23) || 0}px)`
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.preview-container {
  padding: 32px;
  border-radius: var(--border-radius-base);
  background: #fff;
  width: 100%;
}

.title-info {
  margin-bottom: 24px;
  font-size: 20px;
}

.contacts-container {
  width: 25%;
  margin-right: 24px;
}

.embed-url {
  border-radius: var(--border-radius-base);
  width: 100%;
  border: none;
}

.line-center {
  margin: 32px 0;
  height: 1px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.10);
}

.url-partner {
  margin-top: 16px;
  width: 100%;
  padding: 16px;
  border-radius: 12px;
  border: 1px solid #e6e6e6;

  .detail-url {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }
}

.no-ember {
  width: 75%;
  display: flex;
  align-items: center;
  background: #f5f5f7;
  border-radius: var(--border-radius-base);

  .__content {
    width: max-content;
    margin: 0 auto;
    line-height: 1.25rem;
    font-weight: 600;
    text-align: center;
  }
}

.content-title-info {
  color: rgba(0, 0, 0, 0.70);
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 8px;
}

.text-content-info {
  color: #000;
  font-size: 16px;
  line-height: 24px; /* 150% */
  overflow-wrap: break-word;
}
</style>
